export const usePreferenceStore = defineStore('preferences', {
    state: () => ({
        darkMode: localStorage.getItem('darkMode') === "true" || !localStorage.getItem('darkMode'),
        darkModeChecked: localStorage.getItem('darkModeChecked') === "true",
        showUpgradeBox: localStorage.getItem('showUpgradeBox') === "true" || !localStorage.getItem('showUpgradeBox'),
        menuCollapsed: localStorage.getItem('menuCollapsed') === "true",
        selectedPlan: localStorage.getItem('selectedPlan') || ''
    }),
    actions: {
        setDarkMode(state) {
            this.darkMode = state;
            localStorage.setItem('darkMode', state);
        },
        setDarkModeChecked(state) {
            this.darkModeChecked = state;
            localStorage.setItem('darkModeChecked', state);
        },
        setShowUpgradeBox(state) {
            this.showUpgradeBox = state;
            localStorage.setItem('showUpgradeBox', state);
        },
        setMenuCollapsed(state) {
            this.menuCollapsed = state;
            localStorage.setItem('menuCollapsed', state);
        },
        setPlan(plan){
            this.selectedPlan = plan;
            localStorage.setItem('selectedPlan', plan);
        },
        clearSelectedPlan(){
            this.selectedPlan = '';
            localStorage.removeItem('selectedPlan');
        },
        clearShowUpgradeBox(){
            this.showUpgradeBox = '';
            localStorage.removeItem('showUpgradeBox');
        },
        clear(){
            this.clearSelectedPlan();
            this.clearShowUpgradeBox();
        }
    }
})
